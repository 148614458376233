import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DbService {
  baseUrl:string;
  stati:any;
  governi:any;
  politici:any;

  constructor(
    private http: HttpClient,

  ) { 
    this.baseUrl = "https://upedown.rdv.it/";

  }

  initialize(){
    this.listagoverni()
    .then((data)=>{
      this.governi = data;
    });
  }

  listagoverni(){
    let requestUrl = this.baseUrl + "listagoverni.php";
    return this.http.get(requestUrl).toPromise();
  }



  listapolitici(stati_id){
    let dati = {stati_id: stati_id};
    let requestUrl = this.baseUrl + "listapolitici.php";
    return this.http.post(requestUrl, dati).toPromise();
  }

  politico(stati_id, politico_id){
    let dati = {stati_id: stati_id, politico_id: politico_id};
    let requestUrl = this.baseUrl + "listapolitici.php";
    return this.http.post(requestUrl, dati).toPromise();
  }

  classifica(){
    let uid = {
      uid: window.localStorage.getItem('uid')};
    let requestUrl = this.baseUrl + "classifiche.php";
    return this.http.post(requestUrl, uid).toPromise();
  }

  vote(politici_id, voto){
    let votazione = {
      politici_id: politici_id,
      voto: voto,
      uid: window.localStorage.getItem('uid')
    };
    let requestUrl = this.baseUrl + "votazione.php";
    return this.http.post(requestUrl, votazione).toPromise();
  }

  ultimoVoto(politici_id){
    let votazione = {
      politici_id: politici_id,
      uid: window.localStorage.getItem('uid')
    };
    console.log(votazione);
    let requestUrl = this.baseUrl + "ultimovoto.php";
    return this.http.post(requestUrl, votazione).toPromise();
  }
  
  segnala(tipo:string, testo:string, politico_id:string){
    let segnalazione = {
      tipo: tipo,
      testo: testo,
      politico_id: politico_id,
      uid: window.localStorage.getItem('uid')
    };
    let requestUrl = this.baseUrl + "segnala.php";
    return this.http.post(requestUrl, segnalazione).toPromise();
  }

  correggi(surname:string, name:string, politico_id:string){
    let correzione = {
      surname: surname,
      name: name,
      politico_id: politico_id,
      modo:'edit',
      uid: window.localStorage.getItem('uid')
    };
    let requestUrl = this.baseUrl + "correggi.php";
    return this.http.post(requestUrl, correzione).toPromise();
  }

  cancella( politico_id:string){
    let correzione = {
      politico_id: politico_id,
      modo:'delete',
      uid: window.localStorage.getItem('uid')
    };
    let requestUrl = this.baseUrl + "correggi.php";
    return this.http.post(requestUrl, correzione).toPromise();
  }

  aggiungi(surname:string, name:string, stati_id:string){
    let correzione = {
      surname: surname,
      name: name,
      stati_id: stati_id,
      modo:'append',
      uid: window.localStorage.getItem('uid')
    };
    let requestUrl = this.baseUrl + "correggi.php";
    return this.http.post(requestUrl, correzione).toPromise();
  }
}
