import { Injectable } from '@angular/core';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';

@Injectable({
  providedIn: 'root'
})
export class VoteService {

  constructor(
    private uid: UniqueDeviceID,
  ) { }

  initialize(){
    this.uid.get()
    .then((uuid: any) => window.localStorage.setItem('uid',uuid))
    .catch((error: any) => window.localStorage.setItem('uid','999'));
  }
}
