import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService{
  language:string;
  constructor() {
   }


   initialize(){
    if (window.localStorage.getItem('language')==undefined){
      window.localStorage.setItem('language', 'it');
    }     
    this.language = window.localStorage.getItem('language');
   }

   changeLanguage(language){
    window.localStorage.setItem('language', language);
    this.initialize();
  }
}
