import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'governi/:id/:descrizione',
    loadChildren: () => import('./governi/governi.module').then( m => m.GoverniPageModule)
  },
  {
    path: 'politico/:id',
    loadChildren: () => import('./politico/politico.module').then( m => m.PoliticoPageModule)
  },
  {
    path: 'paypal',
    loadChildren: () => import('./paypal/paypal.module').then( m => m.PaypalPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./tutorial/tutorial.module').then( m => m.TutorialPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
